import React from "react";
// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  createUseStyles,
  BlockUI,
  Button,
  Card,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";
import SelectionCardFooter from "./SelectionCardFooter";
import SelectionDisabledCard from "./SelectionDisabledCard";

const useStyles = createUseStyles({
  card: {
    ...STYLES_CARD,
    "& .p-card-footer": {
      width: "100%",
    },
  },
  body: {
    "overflow-y": "scroll",
  },
});

const SelectionCard = ({
  tokens,
  indices,
  setIndices,
  usingCustomHintWords,
  setUsingCustomHintWords,
  setUseParanthesesWords,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const clickSingleChip = (chip, index) => {
    if (indices.includes(index)) {
      setIndices(indices.filter((i) => i !== index));
    } else {
      setIndices(indices.concat(index).sort());
      setUseParanthesesWords(false);
    }
  };

  if (tokens.length === 0) {
    return (
      <Card
        className={`${classes.card} h-full w-full`}
        title={t("exercises.fillblank.titles.selection")}
        footer={<></>}
      >
        {t("exercises.fillblank.texts.no-selection-words")}
      </Card>
    );
  }

  return (
    <BlockUI
      containerClassName="h-full w-full"
      blocked={usingCustomHintWords}
      template={
        <SelectionDisabledCard
          usingCustomHintWords={usingCustomHintWords}
          setUsingCustomHintWords={setUsingCustomHintWords}
        />
      }
    >
      <Card
        className={`w-full h-full ${classes.card}`}
        title={t("exercises.fillblank.titles.selection")}
        subTitle={t("exercises.fillblank.subtitles.selection")}
        footer={<SelectionCardFooter tokens={tokens} setIndices={setIndices} />}
      >
        <div
          className={`${classes.body} h-full flex flex-wrap border-solid border-300 justify-content-evenly`}
        >
          {tokens
            // filter((token) => token.pos !== "PUNCT" && token.pos !== "SYM").
            .map((token, index) => {
              const unnenessary = ["PUNCT", "SPACE", "SYM"];
              if (unnenessary.includes(token.pos)) {
                return;
              }
              const className = `m-1 p-button-raised p-button-rounded ${
                indices.includes(index)
                  ? "p-button-secondary"
                  : "p-button-primary"
              }`;

              const chip = token.text;

              return (
                <Button
                  key={index}
                  label={chip}
                  className={className}
                  onClick={() => clickSingleChip(chip, index)}
                />
              );
            })}
        </div>
      </Card>
    </BlockUI>
  );
};

export default SelectionCard;
