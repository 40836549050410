import React from "react";
import EditableWord from "./Word/EditableWord";

export default function EditableParagraph({
  words,
  indices,
  blanksParanthesesWords,
  useParanthesesWords,
  answers,
  setAnswers,
}) {
  if (!answers) {
    return null;
  }

  return words.map((word, index) => {
    if (indices.includes(index)) {
      return (
        <EditableWord
          key={index}
          word={word}
          paranthesesWord={blanksParanthesesWords[indices.indexOf(index)]}
          input={answers[indices.indexOf(index)]}
          setInput={(input) => {
            setAnswers((prevAnswers) => {
              const newAnswers = [...prevAnswers];
              newAnswers[indices.indexOf(index)] = input;
              return newAnswers;
            });
          }}
          useParanthesesWords={useParanthesesWords}
        />
      );
    }
    return <span key={index}>{" " + word + " "}</span>;
  });
}
