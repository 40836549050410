import React from "react";

import {
  NavigationGrid,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function FillblankCreatorMain() {
  const { t } = useTranslation();

  return (
    <NavigationGrid
      title={t("exercises.fillblank.titles.creator")}
      data={[
        {
          title: t("exercises.fillblank.titles.form"),
          route: ROUTES.EXERCISES.FILLBLANK.CREATOR.FORM,
          description: "",
          label: t("buttons.navigation.go"),
        },
        {
          title: t("exercises.fillblank.titles.reading_item"),
          route: ROUTES.EXERCISES.FILLBLANK.CREATOR.READING_ITEM(),
          description: "",
          label: t("buttons.navigation.go"),
        },
      ]}
    />
  );
}
