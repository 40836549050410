import React from "react";

export default function RevealedParagraph({
  words,
  indices,
  blanksParanthesesWords,
  useParanthesesWords,
}) {
  return words.map((word, index) => {
    if (indices.includes(index)) {
      return (
        <span key={index}>
          {" "}
          <span style={{ textDecoration: "underline" }}>{word}</span>
          {useParanthesesWords
            ? ` (${blanksParanthesesWords[indices.indexOf(index)]}) `
            : " "}
        </span>
      );
    }
    return <span key={index}>{" " + word + " "}</span>;
  });
}
