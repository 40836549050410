import React from "react";

import {
  Button,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

const SelectionDisabledCardFooter = ({ onClickEnable }) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <Button
        className="ml-2 p-button-secondary"
        label={t("exercises.fillblank.buttons.enable-selection-area")}
        onClick={onClickEnable}
      />
    </div>
  );
};

export default SelectionDisabledCardFooter;
