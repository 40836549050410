import React from "react";

import {
  getAllFillBlanks,
  // @ts-ignore
} from "@projectdiction/api-helper";

import {
  useAccessToken,
  useNavigate,
  useUserPreferences,
  Button,
  Card,
  NavigationGrid,
  Skeleton,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function FillblankList() {
  const [entries, setEntries] = React.useState<any>();

  const token = useAccessToken();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const userPreferences = useUserPreferences();

  React.useEffect(() => {
    async function fetchEntries() {
      const response = await getAllFillBlanks(
        token,
        userPreferences?.languages?.studyLanguage
      );

      setEntries(response.data.fillBlanks);
    }
    if (userPreferences) fetchEntries();
  }, [token, userPreferences]);

  if (!entries) {
    return (
      <div className="w-full h-full grid" style={{ overflow: "auto" }}>
        <Skeleton />
      </div>
    );
  }

  if (entries.length === 0) {
    return (
      <Card title={t("exercises.fillblank.titles.solver")}>
        <h1>{t("exercises.fillblank.titles.noExercises")}</h1>
        <p>{t("exercises.fillblank.texts.noExercisesDescription")}</p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(ROUTES.EXERCISES.FILLBLANK.CREATOR.HOME);
          }}
        >
          {t("exercises.fillblank.buttons.create")}
        </Button>
      </Card>
    );
  }

  return (
    <NavigationGrid
      title={t("exercises.fillblank.titles.solver")}
      data={entries.map(({ title, paragraph, _id }) => {
        return {
          title,
          description: paragraph.slice(0, 100),
          route: ROUTES.EXERCISES.FILLBLANK.SOLVE(_id),
          label: t("exercises.buttons.solve"),
        };
      })}
    />
  );
}
