import React from "react";
// @ts-ignore
import { useTranslation } from "react-i18next";
import {
  addFillBlank,
  // @ts-ignore
} from "@projectdiction/api-helper";
import {
  createUseStyles,
  useAccessToken,
  Button,
  Card,
  InputText,
  InputTextarea,
  Skeleton,
  Toast,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";

const useStyles = createUseStyles({
  card: {
    ...STYLES_CARD,
  },
  body: {
    display: "flex",
    "flex-wrap": "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
});

const HintWordsCardFooter = ({ onClickAdd }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Button
        label={t("exercises.fillblank.buttons.add-hint-word")}
        onClick={onClickAdd}
      />
    </div>
  );
};

export default HintWordsCardFooter;
