import React from "react";

import {
  createUseStyles,
  Button,
  Card,
  InputText,
  useDrag,
  useDrop,
  DndProvider,
  HTML5Backend,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  root: {},
  preview: {},
});

const DraggableWord = ({ word }) => {
  const [{ opacity }, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: "BOX",
    item: { word },
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));

  const classes = useStyles();

  return (
    <div className="font-bold col flex justify-content-center align-items-center">
      <div
        className={`${classes.preview} p-2`}
        ref={dragPreview}
        style={{ opacity }}
      >
        {word}
      </div>
    </div>
  );
};

export default DraggableWord;
