import React from "react";

import {
  InputText,
  // @ts-ignore
} from "@projectdiction/common-react";

export default function EditableWord({
  word,
  paranthesesWord,
  input,
  setInput,
  useParanthesesWords,
}) {
  return (
    <span className="inline-flex align-items-center">
      <InputText
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className="m-2 h-2rem"
        style={{ width: `${word.length}rem` }}
      />
      {useParanthesesWords && ` (${paranthesesWord})`}
    </span>
  );
}
