import React from "react";
// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  createUseStyles,
  Card,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";

import PreviewCardFooter from "./PreviewCardFooter";

const useStyles = createUseStyles({
  card: {
    ...STYLES_CARD,
  },
});

const PreviewCard = ({
  tokens,
  indices,
  onClickSave,
  showHelpWords,
  setShowHelpWords,
  useParanthesesWords,
  setUseParanthesesWords,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [showWordsInPreview, setShowWordsInPreview] = React.useState(false);

  if (tokens.length === 0) {
    return (
      <Card
        className={`${classes.card} h-full w-full`}
        title={t("exercises.fillblank.titles.preview")}
        footer={<></>}
      >
        {t("exercises.fillblank.texts.no-preview-words")}
      </Card>
    );
  }

  return (
    <Card
      className={`${classes.card} w-full h-full`}
      title={t("exercises.fillblank.titles.preview")}
      footer={
        <PreviewCardFooter
          showWordsInPreview={showWordsInPreview}
          setShowWordsInPreview={setShowWordsInPreview}
          onClickSave={onClickSave}
          showHelpWords={showHelpWords}
          setShowHelpWords={setShowHelpWords}
          useParanthesesWords={useParanthesesWords}
          setUseParanthesesWords={setUseParanthesesWords}
        />
      }
    >
      <div className="w-full h-full overflow-y-auto">
        {tokens.map((token, index) => {
          const word = token.text;
          if (indices.includes(index)) {
            const wordEnd = useParanthesesWords ? ` (${token.lemma}) ` : " ";
            if (showWordsInPreview) {
              return (
                <span key={index}>
                  {" "}
                  <span style={{ textDecoration: "underline" }}>{word}</span>
                  {wordEnd}
                </span>
              );
            }

            return ` ${"_".repeat(word.length)} ${wordEnd} `;
          }

          return <span key={index}>{" " + word + " "}</span>;
        })}
      </div>
    </Card>
  );
};

export default PreviewCard;
