// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  createUseStyles,
  Button,
  Card,
  InputText,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";

import HintWordsCardFooter from "./HintWordsCardFooter";

const useStyles = createUseStyles({
  card: {
    ...STYLES_CARD,
  },
  body: {},
});

const HintWordsCard = ({ hintWords, setHintWords }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  if (hintWords.length === 0) {
    return (
      <Card
        className={`${classes.card} h-full w-full`}
        title={t("exercises.fillblank.titles.hint-words")}
        footer={<></>}
      >
        {t("exercises.fillblank.texts.no-hint-words")}
      </Card>
    );
  }

  const onClickAdd = () => {
    const newHintWords = [...hintWords];

    if (newHintWords[newHintWords.length - 1] === "") {
      return;
    }

    newHintWords.push("");

    setHintWords(newHintWords);
  };

  return (
    <Card
      className={`${classes.card} w-full h-full`}
      title={t("exercises.fillblank.titles.hint-words")}
      footer={<HintWordsCardFooter onClickAdd={onClickAdd} />}
    >
      <div className={`${classes.body} grid h-full overflow-auto w-full`}>
        {hintWords.map((word, index) => {
          return (
            <div
              className="col-4 flex align-items-center justify-content-between"
              key={index}
            >
              <InputText
                className="mt-1 w-10"
                key={index}
                value={word}
                onChange={(e) => {
                  const newHintWords = [...hintWords];

                  newHintWords[index] = e.target.value;

                  setHintWords(newHintWords);
                }}
              />
              <Button
                className="w-2 p-button-rounded p-button-text p-button-danger cursor-auto"
                icon="pi pi-times"
                onClick={() => {
                  const newHintWords = [...hintWords];

                  newHintWords.splice(index, 1);

                  setHintWords(newHintWords);
                }}
              />
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default HintWordsCard;
