import React from "react";
// @ts-ignore
import { useTranslation } from "react-i18next";
import {
  addFillBlank,
  // @ts-ignore
} from "@projectdiction/api-helper";
import {
  createUseStyles,
  useAccessToken,
  useNavigate,
  useLocation,
  useUserPreferences,
  confirmDialog,
  ConfirmDialog,
  Splitter,
  SplitterPanel,
  Toast,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import InputCard from "./Cards/Input/InputCard";
import SelectionCard from "./Cards/Selection/SelectionCard";
import PreviewCard from "./Cards/Preview/PreviewCard";
import HintWordsCard from "./Cards/HintWords/HintWordsCard";

const useStyles = createUseStyles({
  root: {},
  foo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50%",
    width: "100%",
  },
  hidden: {
    display: "none",
  },
});

function FillblankCreator() {
  const classes = useStyles();

  const { t } = useTranslation();

  const toast = React.useRef<Toast>();

  const [tokens, setTokens] = React.useState([]);

  const [usingCustomHintWords, setUsingCustomHintWords] = React.useState(false);

  const location = useLocation();

  const [title, setTitle] = React.useState(location.state?.title || "");
  const [instructions, setInstructions] = React.useState("");
  const [helpText, setHelpText] = React.useState("");
  const [paragraph, setParagraph] = React.useState(location.state?.text || "");

  const [blanksIndices, _setBlanksIndices] = React.useState([]);

  const [hintWords, _setHintWords] = React.useState([]);

  const setBlanksIndices = (indices) => {
    indices = indices.sort((a, b) => a - b);
    _setBlanksIndices(indices);
    const words = indices.map((index) => tokens[index].text);

    _setHintWords(words);
  };

  const setHintWords = (words) => {
    _setHintWords(words);
    setUsingCustomHintWords(true);
  };

  const token = useAccessToken();

  const navigate = useNavigate();

  const [showHelpWords, setShowHelpWords] = React.useState(false);
  const [useParanthesesWords, setUseParanthesesWords] = React.useState(true);

  const userPreferences = useUserPreferences();

  const onClickSave = async () => {
    if (title === "") {
      toast.current?.show({
        severity: "error",
        summary: t("exercises.fillblank.messages.title-blank"),
      });
      return;
    }

    if (blanksIndices.length === 0 || hintWords.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: t("exercises.fillblank.messages.no-blanks-selected"),
      });
      return;
    }

    const seperator = "1#1#1#";

    const fillblank = {
      title,
      instructions,
      helpText,
      paragraph,
      paragraphTokens: tokens.map((token) => token.text).join(seperator),
      blanksIndices: blanksIndices.join(","),
      blanksParanthesesWords: useParanthesesWords
        ? blanksIndices.map((index) => tokens[index].lemma).join(seperator)
        : seperator,
      useParanthesesWords,
      helpWords: showHelpWords ? hintWords.join(seperator) : seperator,
      showHelpWords,
      studyLanguage: userPreferences.languages.studyLanguage,
    };

    try {
      const response = await addFillBlank(token, fillblank);

      const fillblankId = response.data.fillBlank._id;

      confirmDialog({
        message: t("exercises.fillblank.messages.confirm-save"),
        header: t("exercises.fillblank.titles.confirm-save"),
        icon: "pi pi-check",
        accept: () => {
          navigate(ROUTES.EXERCISES.FILLBLANK.SOLVE(fillblankId));
        },
        reject: () => {
          navigate(ROUTES.EXERCISES.FILLBLANK.SOLVE());
        },
      });
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: t("exercises.fillblank.messages.error-saving"),
      });
    }
  };

  return (
    <div className={`h-full ${classes.root}`}>
      <Splitter gutterSize={0} className="h-full">
        <SplitterPanel className="w-6">
          <Splitter gutterSize={0} layout="vertical" className="w-full">
            <SplitterPanel
              className={classes.foo}
              size={showHelpWords ? 50 : 100}
            >
              <InputCard
                setTokens={setTokens}
                title={title}
                setTitle={setTitle}
                paragraph={paragraph}
                setParagraph={setParagraph}
                instructions={instructions}
                setInstructions={setInstructions}
                helpText={helpText}
                setHelpText={setHelpText}
              />
            </SplitterPanel>

            <SplitterPanel
              className={showHelpWords ? classes.foo : classes.hidden}
              size={50}
            >
              {showHelpWords && (
                <HintWordsCard
                  hintWords={hintWords}
                  setHintWords={setHintWords}
                />
              )}
            </SplitterPanel>
          </Splitter>
        </SplitterPanel>

        <SplitterPanel className="w-6">
          <Splitter gutterSize={0} layout="vertical" className="w-full">
            <SplitterPanel className={classes.foo} size={50}>
              <SelectionCard
                tokens={tokens}
                indices={blanksIndices}
                setIndices={setBlanksIndices}
                usingCustomHintWords={usingCustomHintWords}
                setUsingCustomHintWords={setUsingCustomHintWords}
                setUseParanthesesWords={setUseParanthesesWords}
              />
            </SplitterPanel>

            <SplitterPanel className={classes.foo} size={50}>
              <PreviewCard
                tokens={tokens}
                indices={blanksIndices}
                onClickSave={onClickSave}
                showHelpWords={showHelpWords}
                setShowHelpWords={setShowHelpWords}
                useParanthesesWords={useParanthesesWords}
                setUseParanthesesWords={setUseParanthesesWords}
              />
            </SplitterPanel>
          </Splitter>
        </SplitterPanel>
      </Splitter>
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
}

export default FillblankCreator;
