import React from "react";

import {
  getFillBlank,
  // @ts-ignore
} from "@projectdiction/api-helper";

import {
  useAccessToken,
  useParams,
  createUseStyles,
  DndProvider,
  HTML5Backend,
  TouchBackend,
  Card,
  Skeleton,
  Splitter,
  SplitterPanel,
  Toast,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import SolverHintWordsCard from "./Cards/SolverHintWordsCard";
import SolverPreviewCard from "./Cards/SolverPreviewCard";
import FillBlankSolverFooter from "./FillBlankSolverFooter";

// https://stackoverflow.com/a/46545530

function shuffleArray(unshuffled) {
  const shuffled = unshuffled
    // We put each element in the array in an object, and give it a random sort key
    .map((value) => ({ value, sort: Math.random() }))
    // We sort using the random key
    .sort((a, b) => a.sort - b.sort)
    // We unmap to get the original objects
    .map(({ value }) => value);

  return shuffled;
}

const useStyles = createUseStyles({
  card: {
    "& .p-card-content": {
      "flex-direction": "column",
      height: "100% !important",
    },
    ...STYLES_CARD,
  },
});

export default function FillblankSolver() {
  const classes = useStyles();

  const [fillblank, setFillblank] = React.useState<any>();

  const [hintWords, setHintWords] = React.useState<string[]>([]);

  const [chips, setChips] = React.useState<string[]>([]);

  const [answers, setAnswers] = React.useState<string[]>();

  const { id } = useParams();

  const token = useAccessToken();

  const { t } = useTranslation();

  const toast = React.useRef<Toast>();

  React.useEffect(() => {
    async function fetchData() {
      const response = await getFillBlank(token, id);

      setFillblank(response.data.fillBlank);

      const { helpWords, paragraphTokens, blanksIndices } =
        response.data.fillBlank;

      setHintWords(shuffleArray(helpWords));

      setChips(paragraphTokens);

      setAnswers(new Array(blanksIndices.length).fill(""));
    }

    fetchData();
  }, [id, token]);

  if (!fillblank) {
    return (
      <Card className={classes.card}>
        <div className="grid h-full w-full">
          <Skeleton width="100%" height="30%" className="mb-3"></Skeleton>
          <Skeleton width="100%" height="70%"></Skeleton>
        </div>
      </Card>
    );
  }

  const onCheckClick = () => {
    const correctAnswers = fillblank.blanksIndices.map(
      (blanksIndex) => fillblank.paragraphTokens[blanksIndex]
    );

    const correct = answers?.every(
      (answer, index) =>
        answer.toLowerCase() === correctAnswers[index].toLowerCase()
    );

    if (correct) {
      toast.current?.show({
        severity: "success",
        summary: t("exercises.fillblank.messages.correct-solution"),
        life: 3000,
      });
    } else {
      toast.current?.show({
        severity: "error",
        summary: t("exercises.fillblank.messages.incorrect-solution"),
        life: 3000,
      });
    }
  };

  // https://stackoverflow.com/a/52855084

  const touch = window.matchMedia("(pointer: coarse)").matches;

  return (
    <DndProvider backend={touch ? TouchBackend : HTML5Backend}>
      <div className="h-full w-full flex justify-content-center align-items-center">
        <Card
          className={`${classes.card} h-9 w-9`}
          title={t("exercises.fillblank.titles.solver")}
          footer={<FillBlankSolverFooter onCheckClick={onCheckClick} />}
        >
          <Splitter gutterSize={0} layout="vertical" className="h-full w-full">
            <SplitterPanel
              className="w-full pt-1 flex justify-content-center align-items-center"
              size={25}
            >
              <SolverHintWordsCard hintWords={hintWords} />
            </SplitterPanel>

            <SplitterPanel size={75}>
              <SolverPreviewCard
                title={fillblank.title}
                words={chips}
                indices={fillblank.blanksIndices}
                revealAnswers={false}
                answers={answers}
                setAnswers={setAnswers}
                blanksParanthesesWords={fillblank.blanksParanthesesWords}
                useParanthesesWords={fillblank.useParanthesesWords}
                showHelpWords={fillblank.showHelpWords}
              />
            </SplitterPanel>
          </Splitter>
        </Card>
      </div>
      <Toast ref={toast} />
    </DndProvider>
  );
}
