import React from "react";

import {
  useAccessToken,
  useParams,
  createUseStyles,
  useNavigate,
  Card,
  Skeleton,
  STYLES_CARD,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  getReadingItem,
  // @ts-ignore
} from "@projectdiction/api-helper";

import ReadingItemSection from "./ReadingItemSection";

const useStyles = createUseStyles({
  root: {
    ...STYLES_CARD,
  },
  contentRoot: {
    height: "80vh",
  },
  textCard: {
    height: "97%",
    overflowY: "hidden",
    "& .p-card-body": {
      height: "100%",
    },
    "& .p-card-content": {
      height: "80% !important",
      overflowY: "scroll",
    },
  },
  wordSpan: {
    cursor: "cell",
  },
});

export default function ReadingItem() {
  const [readingItem, setReadingItem] = React.useState<any>();

  const { id } = useParams();

  const token = useAccessToken();

  const classes = useStyles();

  const navigate = useNavigate();

  React.useEffect(() => {
    getReadingItem(token, id)
      .then((res) => {
        setReadingItem(res.data.readingItem);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, token]);

  const rootClass = `${classes.textCard}`;
  const rootStyle = { height: "97%" };

  if (!readingItem) {
    return (
      <Card className={rootClass} style={rootStyle}>
        <Skeleton width="90%" height="90%" />
      </Card>
    );
  }

  const onTextClick = (text) => {
    navigate(ROUTES.EXERCISES.FILLBLANK.CREATOR.FORM, {
      state: { title: readingItem.title, text },
    });
  };

  return (
    <div className="flex align-items-center justify-content-center h-full w-full">
      <Card
        title={readingItem.title}
        subTitle={readingItem.subtitle}
        className={rootClass}
        style={rootStyle}
      >
        <div className="flex flex-wrap h-full">
          {readingItem.text.map(
            (
              text: {
                headingLevel: 0;
                heading: string;
                content: Array<string>;
              },
              index: any
            ) => {
              return (
                <ReadingItemSection
                  key={index}
                  headingLevel={text.headingLevel}
                  heading={text.heading}
                  content={text.content}
                  onTextClick={onTextClick}
                />
              );
            }
          )}
        </div>
      </Card>
    </div>
  );
}
