import React from "react";
import {
  createUseStyles,
  // @ts-ignore
} from "@projectdiction/common-react";
import SolverHintWordsCardFooter from "./SolverHintWordsCardFooter";

// @ts-ignore
import { useTranslation } from "react-i18next";
import DraggableWord from "./PreviewParagraph/Word/DraggableWord";

const useStyles = createUseStyles({
  card: {
    "& .p-card-body": {
      padding: 0,
    },
    "& .p-card-content": {
      height: "100% !important",
    },
  },
});

const SolverHintWordsCard = ({ hintWords }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  if (hintWords.length === 0) {
    return null;
  }

  return (
    <div
      className="grid w-full h-full overflow-auto border-2 align-content-center"
      style={{ maxHeight: "5rem" }}
    >
      {hintWords.map((word, index) => {
        return <DraggableWord word={word} key={index} />;
      })}
    </div>
  );
};

export default SolverHintWordsCard;
