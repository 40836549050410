import React from "react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  splitTextIntoTokens,
  // @ts-ignore
} from "@projectdiction/api-helper";

import {
  createUseStyles,
  useUserPreferences,
  useLocation,
  Button,
  Card,
  InputText,
  InputTextarea,
  Toast,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";
import InputCardFooter from "./InputCardFooter";

const useStyles = createUseStyles({
  card: {
    ...STYLES_CARD,
  },
  body: {},
});

const InputCard = ({
  setTokens,
  title,
  setTitle,
  instructions,
  setInstructions,
  helpText,
  setHelpText,
  paragraph,
  setParagraph,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const userPreferences = useUserPreferences();

  const { state } = useLocation();

  React.useEffect(() => {
    if (state && userPreferences) {
      clickProcess();
    }
  }, [state, userPreferences]);

  function clickProcess() {
    splitTextIntoTokens(
      paragraph,
      userPreferences.languages.studyLanguage
    ).then((response) => {
      setTokens(response.tokens);
    });
  }

  return (
    <Card
      className={`w-full h-full ${classes.card}`}
      title="Input Area"
      footer={<InputCardFooter clickProcess={clickProcess} />}
    >
      <div className={`${classes.body} grid w-full h-full overflow-auto`}>
        <div className="p-float-label col-6">
          <InputText
            className="w-full"
            id="title1634"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          ></InputText>
          <label htmlFor="title1634">
            {t("exercises.fillblank.labels.title")}
          </label>
        </div>

        <div className="p-float-label col-6 mt-2">
          <InputTextarea
            className="w-full"
            style={{ resize: "none" }}
            id="instructions5278"
            rows={1}
            cols={30}
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
          <label htmlFor="instructions5278">
            {t("exercises.fillblank.labels.instructions")}
          </label>
        </div>

        <div className="p-float-label col-6 mt-2">
          <InputTextarea
            className="w-full"
            style={{ resize: "none" }}
            id="helpText5278"
            rows={5}
            cols={30}
            value={helpText}
            onChange={(e) => setHelpText(e.target.value)}
          />
          <label htmlFor="helpText5278">
            {t("exercises.fillblank.labels.help-text")}
          </label>
        </div>

        <div className="p-float-label col-6 mt-2">
          <InputTextarea
            className="w-full"
            style={{ resize: "none" }}
            id="text5278"
            rows={5}
            cols={30}
            value={paragraph}
            onChange={(e) => setParagraph(e.target.value)}
          />
          <label htmlFor="text5278">
            {t("exercises.fillblank.labels.paragraph")}
          </label>
        </div>
      </div>
    </Card>
  );
};

export default InputCard;
