import React from "react";
import {
  createUseStyles,
  Button,
  Card,
  InputText,
  DndProvider,
  HTML5Backend,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";
import SolverPreviewCardFooter from "./SolverPreviewCardFooter";
import DroppableWord from "./PreviewParagraph/Word/DroppableWord";
import EditableWord from "./PreviewParagraph/Word/EditableWord";
import RevealedParagraph from "./PreviewParagraph/RevealedParagraph";
import DroppableParagraph from "./PreviewParagraph/DroppableParagraph";
import EditableParagraph from "./PreviewParagraph/EditableParagraph";

const useStyles = createUseStyles({
  root: {
    ...STYLES_CARD,
  },
});

const SolverPreviewCard = ({
  title,
  words,
  indices,
  revealAnswers,
  answers,
  setAnswers,
  blanksParanthesesWords,
  useParanthesesWords,
  showHelpWords,
}) => {
  const classes = useStyles();

  return (
    <Card
      title={title}
      className={`${classes.card} w-full h-full`}
      footer={<SolverPreviewCardFooter />}
    >
      <div
        className="w-full h-full overflow-auto"
        style={{ maxHeight: "20rem" }}
      >
        {revealAnswers ? (
          <RevealedParagraph
            words={words}
            indices={indices}
            blanksParanthesesWords={blanksParanthesesWords}
            useParanthesesWords={useParanthesesWords}
          />
        ) : showHelpWords ? (
          <DroppableParagraph
            words={words}
            indices={indices}
            answers={answers}
            setAnswers={setAnswers}
            blanksParanthesesWords={blanksParanthesesWords}
            useParanthesesWords={useParanthesesWords}
          />
        ) : (
          <EditableParagraph
            words={words}
            indices={indices}
            blanksParanthesesWords={blanksParanthesesWords}
            useParanthesesWords={useParanthesesWords}
            answers={answers}
            setAnswers={setAnswers}
          />
        )}
      </div>
    </Card>
  );
};

export default SolverPreviewCard;
