import React from "react";

import {
  createUseStyles,
  ROUTES,
  Routes,
  Route,
  ReadingCategories,
  useNavigate,
  // @ts-ignore
} from "@projectdiction/common-react";

import ExerciseMain from "./Exercises/ExercisesMain";
import FillblankMain from "./Exercises/Fillblank/FillBlankMain";
import FillblankCreator from "./Exercises/Fillblank/FillblankCreator/Creator/FillblankCreator";
import FillblankSolver from "./Exercises/Fillblank/FillblankSolver/FillblankSolver";
import FillblankList from "./Exercises/Fillblank/FillblankList";
import FillblankCreatorMain from "./Exercises/Fillblank/FillblankCreator/FillblankCreatorMain";
import ReadingItem from "./Exercises/Fillblank/FillblankCreator/Reading/ReadingItem";

const useStyles = createUseStyles({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
});

export default function App() {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Routes>
        <Route path={ROUTES.EXERCISES.HOME} element={<ExerciseMain />} />

        <Route
          exact
          path={ROUTES.EXERCISES.FILLBLANK.HOME}
          element={<FillblankMain />}
        />

        <Route
          exact
          path={ROUTES.EXERCISES.FILLBLANK.SOLVE()}
          element={<FillblankList />}
        />

        <Route
          path={ROUTES.EXERCISES.FILLBLANK.SOLVE_ID}
          element={<FillblankSolver />}
        />

        <Route
          path={ROUTES.EXERCISES.FILLBLANK.CREATOR.HOME}
          element={<FillblankCreatorMain />}
        />

        <Route
          path={ROUTES.EXERCISES.FILLBLANK.CREATOR.FORM}
          element={<FillblankCreator />}
        />

        <Route
          exact
          path={ROUTES.EXERCISES.FILLBLANK.CREATOR.READING_ITEM()}
          element={
            <ReadingCategories
              onSingleItemClick={(readingItem) =>
                navigate(
                  ROUTES.EXERCISES.FILLBLANK.CREATOR.READING_ITEM(
                    readingItem._id
                  )
                )
              }
            />
          }
        />

        <Route
          path={ROUTES.EXERCISES.FILLBLANK.CREATOR.READING_ITEM_ID}
          element={<ReadingItem />}
        />
      </Routes>
    </div>
  );
}
