import React from "react";

import {
  Button,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

const FillBlankSolverFooter = ({ onCheckClick }) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <Button
        label={t("exercises.fillblank.buttons.check")}
        onClick={onCheckClick}
      />
    </div>
  );
};

export default FillBlankSolverFooter;
