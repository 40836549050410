import React from "react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  Button,
  Checkbox,
  // @ts-ignore
} from "@projectdiction/common-react";

const PreviewCardFooter = ({
  showWordsInPreview,
  setShowWordsInPreview,
  onClickSave,
  showHelpWords,
  setShowHelpWords,
  useParanthesesWords,
  setUseParanthesesWords,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <Checkbox
        key="showHelpWords"
        id="showHelpWords"
        checked={showHelpWords}
        onChange={(e) => setShowHelpWords(e.checked)}
      />
      <label htmlFor="showHelpWords" className="ml-2">
        {t("exercises.fillblank.labels.show-help-words")}
      </label>

      <Checkbox
        key="useParanthesesWords"
        id="useParanthesesWords"
        checked={useParanthesesWords}
        onChange={(e) => setUseParanthesesWords(e.checked)}
      />
      <label
        id="useParanthesesWords"
        htmlFor="useParanthesesWords"
        className="ml-2"
      >
        {t("exercises.fillblank.labels.use-parantheses-words")}
      </label>

      <Button
        className={`${
          showWordsInPreview ? "p-button-secondary" : "p-button-primary"
        }`}
        label={`${showWordsInPreview ? "Hide" : "Show"} Words`}
        onClick={() => {
          setShowWordsInPreview(!showWordsInPreview);
        }}
      />

      <Button
        className="ml-2 p-button-success"
        label={t("exercises.fillblank.buttons.save")}
        onClick={onClickSave}
      />
    </div>
  );
};

export default PreviewCardFooter;
