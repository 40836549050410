import React from "react";

// @ts-ignore
import { useTranslation } from "react-i18next";
import {
  addFillBlank,
  // @ts-ignore
} from "@projectdiction/api-helper";
import {
  createUseStyles,
  Button,
  // @ts-ignore
} from "@projectdiction/common-react";

const useStyles = createUseStyles({
  root: {},
});

const InputCardFooter = ({ clickProcess }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className="w-12 flex justify-content-end">
      <Button
        className="ml-2 p-button-secondary"
        label={t("exercises.fillblank.buttons.process")}
        onClick={clickProcess}
      />
    </div>
  );
};

export default InputCardFooter;
