import React from "react";

import {
  createUseStyles,
  useDrop,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";
import SolverPreviewCardFooter from "../../SolverPreviewCardFooter";
import EditableWord from "./EditableWord";

const useStyles = createUseStyles({
  root: {
    ...STYLES_CARD,
  },
});

const DroppableWord = ({
  word,
  input,
  setInput,
  paranthesesWord,
  useParanthesesWords,
}) => {
  const [, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: "BOX",
    drop: (item) => {
      setInput(item.word);

      return item;
    },
    // Props to collect
    collect: (monitor, props) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      text: monitor.getDropResult(),
      props,
    }),
  }));

  return (
    <span ref={drop}>
      <EditableWord
        word={word}
        input={input}
        setInput={setInput}
        paranthesesWord={paranthesesWord}
        useParanthesesWords={useParanthesesWords}
      />
    </span>
  );

  // return (
  //   <span ref={drop} className={`m-2 h-2rem ${revealWord ? "underline" : ""}`}>
  //     {revealWord ? ` ${input} ` : ` ${"_".repeat(input.length)} `}
  //   </span>
  // );
};

export default DroppableWord;
