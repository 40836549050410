import React from "react";
import DroppableWord from "./Word/DroppableWord";

export default function DroppableParagraph({
  words,
  indices,
  answers,
  setAnswers,
  blanksParanthesesWords,
  useParanthesesWords,
}) {
  if (!answers) {
    return null;
  }

  return words.map((word, index) => {
    if (indices.includes(index)) {
      return (
        <DroppableWord
          key={index}
          word={word}
          useParanthesesWords={useParanthesesWords}
          paranthesesWord={blanksParanthesesWords[indices.indexOf(index)]}
          input={answers[indices.indexOf(index)]}
          setInput={(input) => {
            setAnswers((prevAnswers) => {
              const newAnswers = [...prevAnswers];
              newAnswers[indices.indexOf(index)] = input;
              return newAnswers;
            });
          }}
        />
      );
    }
    return <span key={index}>{" " + word + " "}</span>;
  });
}
