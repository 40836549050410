import React from "react";
// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  createUseStyles,
  BlockUI,
  Button,
  Card,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";
import SelectionDisabledCardFooter from "./SelectionDisabledCardFooter";

const useStyles = createUseStyles({
  card: {
    ...STYLES_CARD,
  },
  body: {
    "overflow-y": "scroll",
  },
});
const SelectionDisabledCard = ({
  usingCustomHintWords,
  setUsingCustomHintWords,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const onClickEnable = () => {
    setUsingCustomHintWords(false);
  };

  return (
    <Card
      className={`${classes.card} w-full h-8`}
      title={t("exercises.fillblank.titles.selection-disabled")}
      footer={<SelectionDisabledCardFooter onClickEnable={onClickEnable} />}
    >
      {t("exercises.fillblank.texts.selection-disabled")}
    </Card>
  );
};

export default SelectionDisabledCard;
