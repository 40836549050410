import {
  NavigationGrid,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

function ExerciseMain() {
  const { t } = useTranslation();

  return (
    <NavigationGrid
      title={t("exercises.titles.exercises")}
      data={[
        {
          title: t("exercises.fillblank.titles.fillblank"),
          route: ROUTES.EXERCISES.FILLBLANK.HOME,
          description: t("exercises.fillblank.description"),
          label: t("buttons.navigation.go"),
        },
      ]}
    />
  );
}

export default ExerciseMain;
