import React from "react";

import {
  Button,
  Checkbox,
  // @ts-ignore
} from "@projectdiction/common-react";

const SelectionCardFooter = ({ tokens, setIndices }) => {
  const [selectedWordClasses, setSelectedWordClasses] = React.useState([]);

  const onWordClassesChange = (e) => {
    let _wordClasses = [...selectedWordClasses];

    if (e.checked) {
      _wordClasses.push(e.value);
    } else {
      _wordClasses = _wordClasses.filter((c) => c !== e.value);
    }

    setSelectedWordClasses(_wordClasses);

    const indices = [];

    tokens.forEach((token, index) => {
      if (_wordClasses.includes(token.pos)) {
        indices.push(index);
      }
    });

    setIndices(indices);
  };

  const wordClasses = [
    { id: "adjectives", value: "ADJ", label: "Adjectives" },
    { id: "adverbs", value: "ADV", label: "Adverbs" },
    { id: "auxiliaries", value: "AUX", label: "Auxiliaries" },
    {
      id: "coordinating-conjunctions",
      value: "CCONJ",
      label: "Coordinating Conjunctions",
    },
    { id: "determiners", value: "DET", label: "Determiners" },
    { id: "interjections", value: "INTJ", label: "Interjections" },
    { id: "nouns", value: "NOUN", label: "Nouns" },
    { id: "numerals", value: "NUM", label: "Numerals" },
    { id: "particles", value: "PART", label: "Particles" },
    { id: "adpositions", value: "ADP", label: "Prepositions" },
    { id: "pronouns", value: "PRON", label: "Pronouns" },
    { id: "proper-nouns", value: "PROPN", label: "Proper Nouns" },
    {
      id: "subordinating-conjunctions",
      value: "SCONJ",
      label: "Subordinating Conjunctions",
    },
    { id: "verbs", value: "VERB", label: "Verbs" },
  ];

  return (
    <div className="flex w-full overflow-x-auto">
      {wordClasses.map((wordClass) => {
        return (
          <div key={wordClass.id} className="flex m-1">
            <Checkbox
              inputId={wordClass.id}
              value={wordClass.value}
              onChange={onWordClassesChange}
              checked={selectedWordClasses.includes(wordClass.value)}
            />
            <label htmlFor={wordClass.id} className="ml-2">
              {wordClass.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default SelectionCardFooter;
